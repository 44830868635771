(function ($) {
	// トップページへのお知らせの表示と表示件数
	$.ajax({
		url: "news.html", //読み込むファイルを指定
		cache: false, //キャッシュを保存するかの指定
		success: function (html) {
			//データ取得後に実行する処理
			$(html)
				.find("#newslist")
				.each(function () {
					//読み込みたい部分の指定
					$("#top-news").html($(this).html()); //読み込んだ外部HTMLを表示する領域を指定
					return false;
				});
			$("#top-news article:gt(2)").remove(); //3件目以降の article を削除
		},
	});

	// 新着情報11件以上でボタン表示
	$("#news #newslist").each(function () {
		var num = $(this).find("article").length;
		if (num >= 10) {
			$(this).find(".c-btn__more").addClass("active");
		} else {
			$(this).find(".c-btn__more").removeClass("active");
		}
	});

	// ボタンテキスト変更
	var newswrap = $("#news #newslist");
	$(function () {
		$("#news #newslist .c-btn__more").on("click", function () {
			if ($(this).text() === "▲ CLOSE") {
				$(this).text("▼ VIEW MORE");
				newswrap.removeClass("open");
			} else {
				$(this).text("▲ CLOSE");
				newswrap.addClass("open");
			}
		});
	});

	// VIEW MOREクリックで新着情報全表示
	$("#news #newslist article:nth-of-type(n+11)").toggle();

	$("#news #newslist .c-btn__more").click(function () {
		$("#newslist article:nth-of-type(n+11)").toggle(500);
	});
})(jQuery);
